// setTurnstileToken.js
'use strict';
import { MERGE_OPERATION_DATA } from '../ActionTypes.js';

import { initTurnstile, getTurnstileToken } from '../resource/turnstile.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';

import { CLOUDFLARE_TURNSTILE_SITE_KEY } from '../RemoteConfigKeys.js';

/**
 * Set turnstile token
 * @kind action
 * @return {Promise} Action promise.
 */
const setTurnstileToken = () => async (dispatch, getState) => {
  const sitekey = getRemoteConfigData(
    getState(),
    CLOUDFLARE_TURNSTILE_SITE_KEY
  );

  if (!sitekey) return dispatch({ type: '' });

  // make sure the script is loaded and the invisible turnstile is rendered
  await initTurnstile({ sitekey });

  const turnstileToken = await getTurnstileToken();

  return dispatch({
    type: MERGE_OPERATION_DATA,
    payload: {
      selectPath: ['turnstile'],
      data: {
        token: turnstileToken,
      },
    },
  });
};

export default setTurnstileToken;
