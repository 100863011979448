// getConfigurations.js
'use strict';
import { createSelector } from '../resource/createCachedSelector.js';

import getOperationData from '../selector/getOperationData.js';
import {
  CONFIG_PRIORITY_CONFIGURE,
  CONFIG_PRIORITY_PRESENCE_CLIENT,
  CONFIG_PRIORITY_PRESENCE_USER,
  configPriority,
} from '../resource/configPriority.js';

/**
 * Select default group id configurations
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @return {array|undefined} The default group id configurations.
 */
const getConfigurations = createSelector(
  state => getOperationData(state, ['config'], 'defaultConfigKey'),
  state => getOperationData(state, ['config'], 'configurationGroups'),
  (defaultGroupId, configGroups) => {
    if (!defaultGroupId || !configGroups) {
      return;
    }
    const sortedConfigurations = [
      CONFIG_PRIORITY_PRESENCE_USER,
      CONFIG_PRIORITY_PRESENCE_CLIENT,
      CONFIG_PRIORITY_CONFIGURE,
    ]
      .sort((a, b) => configPriority[b] - configPriority[a])
      .map(priority => {
        const configGroup = configGroups[priority];
        if (!configGroup) {
          return;
        }
        return configGroup[defaultGroupId];
      });
    return sortedConfigurations.find(configGroup => configGroup != null);
  }
);

export default getConfigurations;
