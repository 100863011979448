// profileConstants.js
'use strict';
import {
  OUTBOX_FEED_STORY,
  OUTBOX_FEED_POST_VIDEO,
  OUTBOX_FEED_POST_IMAGE,
  OUTBOX_FEED_SHORT,
  OUTBOX_FEED_POST,
  OUTBOX_FEED_ALL_DRAFT,
  CREATOR_OUTBOX_FEED_STORY_PIN_MAX,
  CREATOR_OUTBOX_FEED_POST_VIDEO_PIN_MAX,
  CREATOR_OUTBOX_FEED_POST_IMAGE_PIN_MAX,
  CREATOR_OUTBOX_FEED_SHORT_PIN_MAX,
} from '../RemoteConfigKeys.js';

export const ProfileType = {
  ME: 'me',
  USER: 'user',
};

export const ProfileListType = {
  HOME: 'home',
  POST: 'post', // Image and video
  FLIX: 'flix', // Video only
  IMAGE: 'image', // Image only
  STORY: 'story', // With expire time image and video
  SHORT: 'short', // Short duration video
  LIVESTREAM_SESSION: 'livestreamSession',
  ALL: 'all',
};

export const ProfileListPublishStatus = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
};

export const ProfileListMediaType = {
  VIDEO_AND_IMAGE: 'videoAndImage',
  VIDEO: 'video',
  IMAGE: 'image',
};

export const ProfilePostDuration = {
  SHORT: 'SHORT',
};

export const ManageProfileType = {
  MANAGE: 'manage',
  ACCOUNT: 'account',
};

export const ProfileField = {
  DISPLAY_NAME: 'displayName',
  BIOGRAPHY: 'biography',
  PICTURE: 'picture',
  BACKGROUND: 'background',
};

export const DISPLAY_NAME_MAXIMUM_LENGTH = 30;
export const BIOGRAPHY_MAXIMUM_LENGTH = 150;

export const GLOBAL_PROFILE_EDITOR_FIELDS = [
  ProfileField.DISPLAY_NAME,
  ProfileField.PICTURE,
  ProfileField.BIOGRAPHY,
];
export const PROFILE_EDITOR_FIELDS = [
  ProfileField.PICTURE,
  ProfileField.BACKGROUND,
  ProfileField.BIOGRAPHY,
];

export const UserMoreFeature = {
  REPORT: 'report',
  BLOCK: 'block',
  FOLLOW: 'follow',
  SHARE: 'share',
  COPY: 'copy',
  ARCHIVE: 'archive',
  SUBSCRIBE: 'subscribe',
  PROFILE: 'profile',
};

export const ProfilePostListLayout = {
  GRID: 'grid',
  FEED: 'feed',
};

export const PROFILE_FEED_REMOTE_CONFIG_KEY_MAP = {
  [ProfileListType.FLIX]: OUTBOX_FEED_POST_VIDEO,
  [ProfileListType.IMAGE]: OUTBOX_FEED_POST_IMAGE,
  [ProfileListType.STORY]: OUTBOX_FEED_STORY,
  [ProfileListType.SHORT]: OUTBOX_FEED_SHORT,
  [ProfileListType.POST]: OUTBOX_FEED_POST,
};

export const PROFILE_DRAFT_FEED_REMOTE_CONFIG_KEY_MAP = {
  [ProfileListType.ALL]: OUTBOX_FEED_ALL_DRAFT,
};

export const PROFILE_PIN_REMOTE_CONFIG_KEY_MAP = {
  [ProfileListType.FLIX]: CREATOR_OUTBOX_FEED_POST_VIDEO_PIN_MAX,
  [ProfileListType.IMAGE]: CREATOR_OUTBOX_FEED_POST_IMAGE_PIN_MAX,
  [ProfileListType.STORY]: CREATOR_OUTBOX_FEED_STORY_PIN_MAX,
  [ProfileListType.SHORT]: CREATOR_OUTBOX_FEED_SHORT_PIN_MAX,
};
