// fetchRemoteConfig.js
'use strict';
import fetch from '../resource/customFetch.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { getSentry } from '../resource/sentry.js';

import {
  SET_OPERATION_DATA,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

/**
 * Fetch remote config
 * @kind action
 * @param {string} {configId} - configuration id.
 * @return {Promise} Action promise.
 */
const fetchRemoteConfig =
  ({ configId }) =>
  async (dispatch, getState) => {
    const selectPath = ['config', 'configurations', configId];
    const isFetching = getNetworkingData(getState(), selectPath, 'isFetching');
    if (!configId || isFetching) {
      return dispatch({ type: '' });
    }
    const fetchOptions = {
      method: 'GET',
      headers: {
        ...getHeaders(),
      },
    };
    const url = getResourceUrl({
      endpoint: `/configurations/${configId}.json`,
    });
    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });
    try {
      const response = await fetch(url.href, fetchOptions);
      if (!response.ok) {
        await handleFetchError({ response });
      }
      const data = await response.json();
      dispatch({
        type: SET_OPERATION_DATA,
        payload: {
          selectPath: ['config', 'configurationData', configId],
          data,
        },
      });
      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath },
      });
    } catch (error) {
      getSentry().then(({ withScope, captureMessage }) => {
        withScope(scope => {
          scope.setFingerprint(['config', `${configId}.json`]);
          scope.setTag('record', 'config', 'failureRate');
          scope.setExtras({
            configId,
            error,
            fetchOptions: {
              ...fetchOptions,
              headers: Object.fromEntries(fetchOptions.headers.entries()),
            },
          });
          captureMessage(`Fetch remote config failed: ${configId}.json`, 'log');
        });
      });
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
    }
  };

export default fetchRemoteConfig;
