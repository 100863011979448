// savePosts.js
'use strict';
import setWith from 'lodash/setWith';

import { ADD_MESSAGES, MERGE_ASSET_DATA } from '../ActionTypes.js';

import { MessageMergeBehavior } from '../reducer/messages.js';
import objectifyArrayById from '../resource/objectifyArrayById.js';

/**
 * Save posts
 * @kind action
 * @param {array} {[posts = []]} - posts payload from backend.
 * @param {string} {[behavior = MessageMergeBehavior.OVERRIDE]} - merging behavior when saving
 * @param {object} {[copyFieldTo = {}]} - copy field to desired select path.
 * @return {Promise} Action promise.
 */
const savePosts =
  ({
    posts = [],
    behavior = MessageMergeBehavior.OVERRIDE,
    copyFieldTo = {},
  } = {}) =>
  async dispatch => {
    const assetObject = posts.reduce((accumulator, message) => {
      (message.assets || []).forEach(asset => {
        const assetObject = {
          id: asset.id,
          contentType: asset['content-type'],
        };
        if (asset['duration']) {
          assetObject.duration = asset['duration'];
        }
        accumulator[asset.id] = assetObject;
      });
      return accumulator;
    }, {});
    const messageObject = objectifyArrayById({
      array: posts.map(item => {
        const message = {
          ...item,
          ...(item.metadata || {}),
          ...(item.metadata?.source_id
            ? {
                sourceId: item.metadata.source_id,
              }
            : {}),
          ...(item.metadata?.cta_url
            ? {
                ctaUrl: item.metadata.cta_url,
              }
            : {}),
        };
        delete message.media;
        delete message.mediaType;
        delete message.metadata;
        delete message.source_id;
        delete message.cta_url;
        if (item.unlock_price != null) {
          message.unlockPrice = item.unlock_price;
          delete message.unlock_price;
        }
        if (item.postedAt != null || item.timestamp != null) {
          message.postedAtUnix = item.postedAt || item.timestamp;
          delete message.postedAt;
          delete message.timestamp;
        }
        if (item.expiresAt != null) {
          message.expiresAtUnix = item.expiresAt;
          delete message.expiresAt;
        }
        if (item.createdAt != null) {
          message.createdAtUnix = item.createdAt;
          delete message.createdAt;
        }
        if (item.nbf != null) {
          message.scheduledAtUnix = item.nbf;
          delete message.nbf;
        }
        if (item.sender != null) {
          message.senderId = item.sender;
          delete message.sender;
        }
        if (item.assets != null) {
          message.assetIds = item.assets.map(asset => asset.id);
          delete message.assets;
        }
        if (item.replyOf) {
          // The `replyOf` might be object or string. Ref: https://swaglive.slack.com/archives/CQR52ULQ2/p1695708715400329?thread_ts=1695708033.429429&cid=CQR52ULQ2
          message.replyOfId = item.replyOf?.id || item.replyOf;
        }
        const needCopyEntries = Object.entries(copyFieldTo);
        if (needCopyEntries.length) {
          needCopyEntries.forEach(([fieldName, selectPath]) => {
            setWith(
              message,
              [...selectPath, fieldName],
              message[fieldName],
              Object
            );
          });
        }
        return message;
      }),
    });
    dispatch({
      type: MERGE_ASSET_DATA,
      payload: {
        selectPath: [],
        data: assetObject,
      },
    });
    return dispatch({
      type: ADD_MESSAGES,
      payload: {
        messages: messageObject,
      },
      behavior,
    });
  };

export default savePosts;
