// retryWithBackoff.js
'use strict';

/**
 * Retry with backoff
 * @param {object} options
 * @param {() => Promise} options.promiseFunction
 * @param {(retries: number) => number} [options.getNextTimeToWait]
 * @param {(retries: number) => Promise} [options.onRetry]
 * @param {(e: any, retries: number) => Promise} [options.onError]
 * @param {number} [options.maxRetries=3]
 * @return {Promise}
 */
const retryWithBackoff = ({
  promiseFunction,
  getNextTimeToWait = retries => 2 ** retries * 1000,
  onRetry,
  onError,
  maxRetries = 3,
}) => {
  async function retry(retries) {
    try {
      if (retries > 0) {
        const timeToWait = getNextTimeToWait(retries);
        await waitFor(timeToWait);
      }
      return await promiseFunction();
    } catch (error) {
      if (retries < maxRetries) {
        onRetry?.(retries);
        return retry(retries + 1);
      } else {
        if (onError) onError?.(error, retries);
        else throw error;
      }
    }
  }

  return retry(0);
};

function waitFor(milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export default retryWithBackoff;
