// reduxStoreInitializePromise.js
'use strict';
import { getIsInNext } from './getJsEnvironment.js';

const isNext = getIsInNext();

export let reduxStoreInitializeResolve;

/**
 * Pending redux store Initialized
 *
 * In Next.js, the initClient action restores the redux store from IndexedDB.
 * While some actions might execute before Next.js dispatch initClient action.
 * We need to wait for the initClient action completed to continue some actions.
 *
 */
const createReduxStoreInitializePromise = () =>
  new Promise(resolve => {
    reduxStoreInitializeResolve = resolve;
    if (!isNext) resolve();
  });

export const reduxStoreInitializePromise = createReduxStoreInitializePromise();

export default reduxStoreInitializePromise;
