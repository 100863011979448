// zendesk.js
'use strict';
import env from '../resource/env.js';
import loadScriptTag from '../resource/loadScriptTag.js';

const envZendeskKey = env.ZENDESK_KEY;

export const loadZendeskScript = async ({ zendeskKey }) => {
  if (!zendeskKey && !envZendeskKey) {
    return;
  }
  // ref: https://support.zendesk.com/hc/en-us/articles/4500748175258-Installing-the-Web-Widget-for-messaging
  return loadScriptTag({
    id: 'ze-snippet',
    async: true,
    src: `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey || envZendeskKey}`,
  });
};
