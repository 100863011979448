// partner.js
'use strict';
import env from '../resource/env.js';

/**
 * Get partner name
 * @return {string} return partner name.
 */
export const getPartnerName = () => {
  return env.PARTNER_NAME;
};

/**
 * Get is china partner.
 * @return {boolean} return true if user is china partner.
 */
export const getIsChinaPartner = () => !!getPartnerName();
