// getAbTestingData.js
'use strict';
import { Collection, Map } from 'immutable';

import createCachedSelector from '../resource/createCachedSelector.js';

const defaultSelectPath = [];
const defaultDataKey = '';
const defaultMap = new Map();

/**
 * Select ab testing data by select path
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {array} selectPath - select path.
 * @param {string} dataKey - data key.
 * @param {string} priority - get specific priority data.
 * @return {any} The selected ab testing data.
 */
const getAbTestingData = createCachedSelector(
  (state, selectPath, dataKey, priority) => getMeAbDataMap(state, priority),
  (state, selectPath = defaultSelectPath) => selectPath.join(':'),
  (state, selectPath, dataKey) => dataKey,
  (abData = defaultMap, selecPath = '', dataKey = defaultDataKey) => {
    let result = abData.getIn(selecPath.split(':'));
    if (dataKey) {
      result = abData?.get(dataKey);
      if ('variableObject' === dataKey) {
        const experimentMap = abData?.get('experiments');
        if (experimentMap) {
          result = experimentMap.reduce((accumulated, item) => {
            const varialbeMap = item?.get('variables');
            return accumulated.mergeDeep(varialbeMap);
          }, new Map());
        }
      }
    }
    return result instanceof Collection ? result.toJS() : result;
  }
)(
  (state, selectPath = defaultSelectPath, dataKey = defaultDataKey, priority) =>
    `${selectPath.join(':')}:${dataKey}:${priority}`
);

export default getAbTestingData;

/**
 * Get me ab data map
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} priority - get specific priority data.
 * @return {Immutable.Map|undefined} The selected me ab data.
 */
const getMeAbDataMap = createCachedSelector(
  state => state.getIn(['me', 'ab']),
  (state, priority) => priority,
  (meAbData = defaultMap, priority) => {
    const abData = priority != null ? meAbData.get(priority) : undefined;
    return abData;
  }
)((state, priority) => `${priority}`);
