// fetchMe.js
'use strict';

import {
  MERGE_USER,
  MERGE_ME_DATA,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';
import fetch from '../resource/customFetch.js';
import handleFetchError from '../resource/handleFetchError.js';
import getMeData from '../selector/getMeData.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import { setName, isDevEnvironment } from '../resource/mixpanel.js';

const selectPath = ['me', 'data'];

/**
 * Fetch me
 * @kind action
 * @param {boolean} {isForceFetch} bypass fetching flag
 * @return {Promise} Action promise.
 */
const fetchMe =
  ({ isForceFetch } = { isForceFetch: false }) =>
  async (dispatch, getState) => {
    const token = getMeData(getState(), 'token');
    const isFetching = getNetworkingData(getState(), selectPath, 'isFetching');

    if (isFetching && !isForceFetch) {
      return;
    }
    if (!token) {
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error: 'no token' },
      });
    }

    const uri = getResourceUrl({ endpoint: '/me' });

    const fetchOptions = {
      headers: {
        ...getHeaders(),
        Authorization: `Bearer ${token}`,
      },
    };

    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });

    try {
      let response = await fetch(uri.href, fetchOptions);

      if (!response.ok) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchOptions,
          fetchUrl: uri,
        });
      }

      const payload = await response.json();

      delete payload.balances;
      delete payload.balance;

      dispatch({ type: MERGE_ME_DATA, payload });
      dispatch({
        type: MERGE_USER,
        payload: {
          user: {
            id: payload.id,
            username: payload.username,
            biography: payload.biography,
          },
        },
      });

      if (isDevEnvironment && payload.username) {
        setName({ name: payload.username });
      }

      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath },
      });
    } catch (error) {
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
    }
  };

export default fetchMe;
