// assetConstants.js
'use strict';

export const Purpose = {
  POST: 'post',
  AUP: 'aup',
  KYC_FACE: 'face',
  KYC_DOCUMENT: 'kycDocument',
  KYC_DOCUMENT_BACK: 'kycDocumentBack',
  KYC_PASSBOOK: 'kycPassbook',
  ME_PICTURE: 'mePicture',
  ME_BACKGROUND: 'meBackground',
  CHAT: 'chat',
};

export const ArtifactType = {
  THUMBNAIL: 'thumbnail',
  TRAILER: 'trailer',
};
