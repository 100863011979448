// fetchMessageDetail.js
'use strict';
import fetch from '../resource/customFetch.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';
import getMeData from '../selector/getMeData.js';
import getRouterData from '../selector/getRouterData.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import getMessageData from '../selector/getMessageData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import savePosts from '../action/savePosts.js';

import { FETCH_MESSAGE_DETAIL_CACHE_BUSTER_QUERIES } from '../RemoteConfigKeys.js';

import {
  MERGE_USER,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

import { reduxStoreInitializePromise } from '../resource/reduxStoreInitializePromise.js';

/**
 * Fetch message detail
 * @kind action
 * @param {string} {id} - message id.
 * @param {object} {[httpProxyHeaders = {}]} - http proxy headers for SSR.
 * @param {boolean} {forceRefetch} - whether or not to skip cache
 * @return {Promise} Action promise.
 */
const fetchMessageDetail =
  ({ id, httpProxyHeaders = {}, forceRefetch = false }) =>
  async (dispatch, getState) => {
    // guarantee have the latest store, mainly for token
    await reduxStoreInitializePromise;

    const state = getState();

    const selectPath = ['messages', id, 'detail'];

    // CDN buster to enable fetch with token.
    // ex: For creators review their own hidden messages.
    const locationSearch = getRouterData(state, 'location.search');
    const searchParams = new URLSearchParams(locationSearch);
    const busterQueries = getRemoteConfigData(
      state,
      FETCH_MESSAGE_DETAIL_CACHE_BUSTER_QUERIES
    );
    const shouldBusterFetch = busterQueries.some(q => searchParams.has(q));

    const isFetching = getNetworkingData(state, selectPath, 'isFetching');
    if (isFetching && !shouldBusterFetch) {
      return { type: '' };
    }

    // Since `SET_NETWORKING_FETCHING` will replace last etag value, we need to use state before `SET_NETWORKING_FETCHING` dispatch.
    // But seems server not return `etag` now.
    const lastEtag = getNetworkingData(state, selectPath, 'etag');

    const url = getResourceUrl({ endpoint: `/messages/${id}` });
    const fetchOptions = {
      headers: {
        ...getHeaders(),
        ...httpProxyHeaders,
      },
    };

    const token = getMeData(state, 'token');
    if (token) {
      fetchOptions.headers.Authorization = `Bearer ${token}`;
      if (shouldBusterFetch || forceRefetch) {
        url.searchParams.set('_', Math.floor(Date.now() / 1000));
      }
    }

    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });

    try {
      let response = await fetch(url.href, fetchOptions);

      if (200 !== response.status) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchOptions,
          fetchUrl: url,
        });
      }

      const etag = response.headers.get('etag');
      if (etag && etag === lastEtag) {
        return dispatch({
          type: SET_NETWORKING_SUCCESS,
          payload: { selectPath, etag },
        });
      }

      const { replyOf, root: rootMessage, ...message } = await response.json();

      let messages = [];
      const mainMessage = { ...message };

      if (replyOf) {
        messages.push(replyOf);
        mainMessage.replyOfId = replyOf.id;
      }

      if (rootMessage) {
        messages.push(rootMessage);
        mainMessage.rootId = rootMessage.id;
      }

      if (undefined != mainMessage.replyPrice) {
        dispatch({
          type: MERGE_USER,
          payload: {
            user: {
              id: mainMessage.sender,
              replyPrice: mainMessage.replyPrice,
            },
          },
        });
      }

      delete mainMessage.replyPrice;

      messages.push(mainMessage);

      dispatch(
        savePosts({
          posts: messages.map(message => {
            // Auto voice and auto story message's postedAt is a setting time not a post time
            if (
              message?.mediaType === 'audio/mp4' ||
              message?.mediaType === 'video/mp4'
            ) {
              const existPostedAt = getMessageData(getState(), id, 'postedAt');
              const existPostedAtUnix = getMessageData(
                getState(),
                id,
                'postedAtUnix'
              );
              if (existPostedAt) delete message.postedAt;
              if (existPostedAtUnix) delete message.postedAtUnix;
            }
            return message;
          }),
        })
      );
      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath, etag },
      });
    } catch (error) {
      let errorObject = null;
      try {
        errorObject = { message: error.message };
        // eslint-disable-next-line no-empty
      } catch (_) {}

      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error: errorObject || error },
      });
    }
  };

export default fetchMessageDetail;
