// getToObject.js
'use strict';
import { LRUCache } from 'lru-cache';

const cache = new LRUCache({ max: 256 });

/**
 * @typedef Path
 * @property {string} pathname - A URL pathname, beginning with a /.
 * @property {string} search - A URL search string, beginning with a ?.
 * @property {string} hash - A URL fragment identifier, beginning with a #.
 */

/**
 * Get to object
 * @param {string|Path} {[to = '']} - destination url.
 * @param {string} {[language = 'en']} - language code.
 * @return {Path}
 */
const getToObject = ({ to = '', language = 'en' } = {}) => {
  const isObject = typeof to === 'object';
  const cacheKey = isObject
    ? `${language}:${to.pathname || ''}${to.search || ''}${to.hash || ''}`
    : to;
  let path = cache.get(cacheKey);
  if (path) return path;
  if (isObject) {
    path = { ...to };
  } else {
    path = parsePath(to);
  }
  const searchParams = new URLSearchParams(path.search);
  searchParams.set('lang', language);
  path.search = `?${searchParams.toString()}`;
  cache.set(cacheKey, path);
  return path;
};

export default getToObject;

/**
 * Parses a string URL path into its separate pathname, search, and hash components.
 * @param {string} {path} - url path.
 * @return {Path}
 */
export const parsePath = path => {
  let parsed = {};
  if (path) {
    const hasPath = path.startsWith('/');
    const url = new URL(path, 'https://swag.live');
    if (url.hash) parsed.hash = url.hash;
    if (url.search) parsed.search = url.search;
    if (hasPath && url.pathname) parsed.pathname = url.pathname;
  }
  return parsed;
};
