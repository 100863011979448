// wordsFilter.js
import env from '../resource/env.js';

// CharCodeRange:
// ['\uFF41-\uFF5A'] 65345 - 65370
// ['\uFF21-\uFF3A'] 65313 - 65338
// ['a-z'] 00097 - 00122

const defaultForbiddenWords = env.MASQUED_PHRASES;

const HALF_WIDTH_CHARCODE_OFFSET = 65313 - 97;

const charToHalfWidth = string => {
  if (!string || typeof string !== 'string') return '';
  return string.replace(/[\uFF21-\uFF5A]*/gm, match => {
    const str = match.split('');
    if (str?.length) {
      return str
        .map(s => {
          const convertedCharCode =
            s.charCodeAt(0) - HALF_WIDTH_CHARCODE_OFFSET;
          return String.fromCharCode(convertedCharCode);
        })
        .join('');
    }
    return match;
  });
};

const handleRegexString = (forbiddenWordList = '') =>
  forbiddenWordList.replace(/(\|)([\w]*)/gi, (_, p1, p2) =>
    p1 && p2 ? `|\\b${p2}\\b` : '|'
  );

/**
 * Words Filter
 * @param {string} { value } - input value
 * @param {string} { filterRefString } - filter reference string
 * @return {string} filter and replaced string.
 */

export const wordsFilter = ({
  value: string,
  filterRefString = defaultForbiddenWords,
}) => {
  if (!filterRefString) return string;
  const regex = new RegExp(handleRegexString(filterRefString), 'gi');
  const halfWidthString = charToHalfWidth(string);
  return halfWidthString.replace(regex, '🙈 🙉 🙊');
};

/**
 * Match Filter Words
 * @param {string} { value } - input value
 * @param {string} { filterRefString } - filter reference string
 * @return {array} matched filter words, if not any match words will return null.
 */

export const matchFilterWords = ({
  value: string,
  filterRefString = defaultForbiddenWords,
}) => {
  if (!filterRefString) return null;
  const regex = new RegExp(handleRegexString(filterRefString), 'gi');
  const halfWidthString = charToHalfWidth(string);
  return halfWidthString.match(regex);
};
