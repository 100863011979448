// navigationAction.js
'use strict';
import {
  push as historyPush,
  replace as historyReplace,
} from 'redux-first-history';

import getToObject from '../resource/getToObject.js';
import i18n from '../resource/i18n.js';

/**
 * Wrap history.push action, try to keep `lang` query
 * @kind action
 * @param {string|Path} to - destination url.
 * @param {any} state - data to associate with new location.
 * @return {Promise} Action promise.
 */
export const push = (to, state) => async dispatch => {
  return dispatch(
    historyPush(getToObject({ to, language: i18n.language }), state)
  );
};

/**
 * Wrap history.replace action, try to keep `lang` query
 * @kind action
 * @param {string|Path} to - destination url.
 * @param {any} state - data to associate with new location.
 * @return {Promise} Action promise.
 */
export const replace = (to, state) => async dispatch => {
  return dispatch(
    historyReplace(getToObject({ to, language: i18n.language }), state)
  );
};
