// getAssetAverageProgress.js
'use strict';
import { Map } from 'immutable';
import createCachedSelector from '../resource/createCachedSelector.js';

import { ArtifactType } from '../resource/assetConstants.js';

const defaultMap = new Map();
const defaultArray = [];

/**
 * Select upload progress
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {array} assetIds - upload job asset ids.
 * @return {number} Return average progress ratio.
 */
const getAssetAverageProgress = createCachedSelector(
  (state, assetIds) => assetIds,
  state => state.get('assets'),
  (assetIds = defaultArray, assetMap = defaultMap) => {
    const artifactProgressList = assetIds
      .flatMap(assetId =>
        Object.values(ArtifactType).map(artifactType =>
          assetMap.getIn([assetId, artifactType, 'progress'])
        )
      )
      .filter(progress => progress != null);
    const assetProgressList = assetIds.map(
      assetId => assetMap.getIn([assetId, 'progress']) || 0
    );
    const totalList = [...assetProgressList, ...artifactProgressList];
    const totalProgress = totalList.reduce(
      (accumulator, current) => accumulator + current,
      0
    );
    if (!totalProgress || !totalList.length) {
      return 0;
    }
    return Math.floor(totalProgress / totalList.length);
  }
)((state, assetIds = defaultArray) => `${assetIds.join(':')}`);

export default getAssetAverageProgress;
