// i18nServer.js
'use strict';
import I18n from 'i18next';
import HttpBackend from 'i18next-http-backend';

import { resourceLog } from '../resource/debug.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import {
  i18nCommonOption,
  i18CommonHttpBackendOption,
  localeConvertor,
} from '../resource/i18nCommon.js';

export const i18nOptions = {
  ...i18nCommonOption,
  backend: {
    ...i18CommonHttpBackendOption,
    reloadInterval: 5 * 60 * 1000, // 5 minutes
    request: async (options, locale, payload, callback) => {
      let status,
        langPack = {};

      const url = getResourceUrl({ endpoint: '/translations' });
      const lang = localeConvertor({ locale, isISO639: true });
      url.searchParams.append('lang', lang);

      try {
        const res = await fetch(url);
        status = res.status;
        langPack = await res.json();
      } catch (err) {
        resourceLog('i18n fetch /translations error', err);
        return callback(err);
      }

      resourceLog('i18n loaded langpack', { lang, status });
      callback(null, { data: langPack, status });
    },
  },
};

export const createI18nInstance = () => {
  return I18n.createInstance(i18nOptions).use(HttpBackend);
};
