// intercomScript.js
'use strict';
import env from '../resource/env.js';
import { getIsInPwa } from '../resource/getUserAgent.js';

const intercomId = env.INTERCOM_ID;
const webapp_version = env.TAG_NAME || env.BRANCH_NAME || 'local';

export const defaultIntercomSetting = {
  app_id: intercomId,
  custom_launcher_selector: '#intercom_launcher',
  webapp_version,
  webapp_is_pwa: getIsInPwa(),
};

const intercomScript = `
window.intercomSettings = ${JSON.stringify(defaultIntercomSetting)};

(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;}})();

window.Intercom('boot');
if (window.intercomLoadedCallbacks) {
  window.intercomLoadedCallbacks.forEach(callback => callback());
}
`;

export default intercomScript;
