// getRouterData.js
'use strict';
import { Collection, fromJS } from 'immutable';
import createCachedSelector from '../resource/createCachedSelector.js';

/**
 * Select router data by data key
 * TODO: can not get correct search on SSR
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string|Array} dataKey - select key.
 * @return {any} The selected router data.
 */
const getRouterData = createCachedSelector(
  state => state.get('router'),
  (state, dataKey) =>
    Array.isArray(dataKey)
      ? dataKey
      : dataKey?.replace(/^\.|\.$/g, '').split('.'),
  (router, dataKey) => {
    let result = undefined;
    if (router == null) {
      return result;
    }
    const immutableRouter =
      router instanceof Collection ? router : fromJS(router);

    if (!dataKey) {
      return router instanceof Collection ? router.toJS() : router;
    }

    result = immutableRouter.getIn([...dataKey]);
    return result instanceof Collection ? result.toJS() : result;
  }
)((state, dataKey) =>
  Array.isArray(dataKey) ? dataKey.join('.') : dataKey ?? 'router'
);

export default getRouterData;
