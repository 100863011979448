// subscribePresenceMessage.js
'use strict';
import getMeData from '../selector/getMeData.js';
import * as MessageTypes from '../serviceWorker/AppMessageTypes.js';
import { sendMessageToSW } from '../serviceWorker/helpers.js';

/**
 * Subscribe presence message
 * @kind action
 * @param {string} {messageId} - message id.
 * @param {string} {uploadJobId} - upload job id.
 * @return {Promise} Action promise.
 */
const subscribePresenceMessage =
  ({ messageId, uploadJobId }) =>
  async (dispatch, getState) => {
    const token = getMeData(getState(), 'token');
    if (!token || !messageId) {
      return dispatch({ type: '' });
    }
    return sendMessageToSW({
      type: MessageTypes.SUBSCRIBE_PRESENCE_MESSAGE_CHANNEL,
      payload: {
        messageId,
        uploadJobId,
      },
    });
  };

export default subscribePresenceMessage;
