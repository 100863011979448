// getListData.js
'use strict';
import { Collection, Map } from 'immutable';
import createCachedSelector from '../resource/createCachedSelector.js';

const defaultMap = new Map();

/**
 * Select list data by data key
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {array} selectPath - select path.
 * @param {string} dataKey - select data key.
 * @return {any} The selected list data.
 */
const getListData = createCachedSelector(
  (state, selectPath) => state.getIn(['lists', ...selectPath]),
  (state, selectPath, dataKey) => dataKey,
  (state, selectPath, dataKey, filterIds) => filterIds,
  (list = defaultMap, dataKey, filterIds) => {
    let result = undefined;
    if (list) {
      result = list.get(dataKey);
      if ('itemIds' === dataKey) {
        result = list.get('itemIds');
        if (filterIds && filterIds.length > 0) {
          result = list.get('itemIds')?.filter(id => !filterIds.includes(id));
        }
      } else if ('lastItemId' === dataKey) {
        result = list?.get('itemIds')?.last();
      }
    }
    return result instanceof Collection ? result.toJS() : result;
  }
)((state, selectPath, dataKey) => `${selectPath.join(':')}:${dataKey}`);

export default getListData;
