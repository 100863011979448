// loadIntercomScript.js
'use strict';
import env from '../resource/env.js';
import intercomScript from '../resource/intercomScript.js';
import loadScriptTag from '../resource/loadScriptTag.js';

const intercomId = env.INTERCOM_ID;

const loadIntercomScript = async () => {
  await loadScriptTag({
    id: 'intercom-sdk-script',
    async: true,
    src: `https://widget.intercom.io/widget/${intercomId}`,
  });
  return loadScriptTag({
    id: 'intercom-exec-script',
    async: true,
    textContent: intercomScript,
  });
};

export default loadIntercomScript;
