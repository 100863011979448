// translationNamespace.js
'use strict';

// Ref: https://swaglive.slack.com/canvas/C05QRG0SC5U
export const TranslationNamespace = {
  LIVESTREAM: 'feature:livestream',
  CHAT: 'feature:chat',
  POST: 'feature:post',
  SHOP: 'feature:shop',
  SETTINGS: 'feature:settings',
  JOURNAL: 'feature:journal',
  PROFILE: 'feature:profile',
  DASHBOARD: 'feature:dashboard',
  AUTH: 'feature:auth',
  FEED: 'feature:feed',
  GIFT: 'feature:gift',
  PRODUCT: 'feature:product',
  SEARCH: 'feature:search',
  BACKPACK: 'feature:backpack',
  NOTIFICATION: 'feature:notification',
  GENERAL: 'feature:general',
  CAMPAIGN: 'feature:campaign',
  SEO: 'feature:seo',
  CREATOR_ONBOARDING: 'feature:creator_onboarding',
  USER_KYC: 'feature:user_kyc',
  DEFAULT: 'translation',
};
