// i18nClient.js
'use strict';
import I18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import chunk from 'lodash/chunk';
import { sanitize } from 'isomorphic-dompurify';

import env from '../resource/env.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { resourceLog } from '../resource/debug.js';
import { getIsInPwa } from '../resource/getUserAgent.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';
import {
  localeConvertor,
  i18nCommonOption,
  i18CommonHttpBackendOption,
} from '../resource/i18nCommon.js';

const I18N_FETCH_THROTTLE_TIME = +env.I18N_FETCH_THROTTLE_TIME || 100;
const I18N_LANGUAGE_CHANGED_CHUNK_SIZE =
  +env.I18N_LANGUAGE_CHANGED_CHUNK_SIZE || 60;

let isTranslationResourceLocked = false;
const missingGroups = new Set();
const missingKeys = new Set();
const usedGroups = new Set();
const groupsStatus = {};
const keysStatus = {};

let timer = null;
let nextFetchTimestamp = Date.now() + I18N_FETCH_THROTTLE_TIME;

const INTERPOLATION_REGEX = /<0>.*?<\/0>/; // TODO: remote config

const getTranslationsUrl = locale => {
  const url = getResourceUrl({ endpoint: '/translations' });
  const lang = localeConvertor({ locale, isISO639: true });
  url.searchParams.append('lang', lang);

  return url;
};

export const createI18nInstance = () => {
  const fetchMissingTranslations = async () => {
    clearTimeout(timer);

    timer = setTimeout(
      async () => {
        if (missingKeys.size || missingGroups.size) {
          nextFetchTimestamp = Date.now() + I18N_FETCH_THROTTLE_TIME;
          const locale = i18n.language;

          if (!groupsStatus[locale]) groupsStatus[locale] = {};
          if (!keysStatus[locale]) keysStatus[locale] = {};

          // add missing key translation default to empty string, also prevent missingKeyHandler infinite loop
          i18n.addResourceBundle(
            locale,
            TranslationNamespace.DEFAULT,
            [...missingKeys].reduce((acc, cur) => ({ ...acc, [cur]: '' }), {})
          );

          let resourceBundle = {};

          if (missingGroups.size) {
            const missingGroupFetches = [];
            const currentMissingGroups = [...missingGroups];
            currentMissingGroups.forEach(group => {
              if (!groupsStatus[locale][group]) {
                groupsStatus[locale][group] = 'fetching';
                const missingGroupUrl = getTranslationsUrl(locale);
                missingGroupUrl.searchParams.append('group', group);
                missingGroupFetches.push(fetch(missingGroupUrl));
              } else if (groupsStatus[locale][group] === 'fetched')
                missingGroups.delete(group);
            });

            if (missingGroupFetches.length) {
              try {
                let allRes = await Promise.all(missingGroupFetches);
                const allLangPacks = await Promise.all(
                  allRes.map(res => res.json())
                );
                const langPack = allLangPacks.reduce(
                  (acc, cur) => ({ ...acc, ...cur }),
                  {}
                );
                const langPackKeys = Object.keys(langPack);

                if (langPackKeys.length) {
                  resourceBundle = {
                    ...resourceBundle,
                    ...langPack,
                  };
                  // delete fetched missing key from group
                  langPackKeys.forEach(key => missingKeys.delete(key));
                }
              } catch (error) {
                resourceLog(
                  'i18n fetch /translations error when fetch missing groups',
                  error
                );
              } finally {
                // delete the fetched missing groups to avoid infinite loop
                currentMissingGroups.forEach(group => {
                  missingGroups.delete(group);
                  groupsStatus[locale][group] = 'fetched';
                });
              }
            }
          }

          if (missingKeys.size) {
            const missingKeysUrl = getTranslationsUrl(locale);
            const currentMissingKeys = [...missingKeys];
            currentMissingKeys.forEach(key => {
              if (!keysStatus[locale][key]) {
                keysStatus[locale][key] = 'fetching';
                missingKeysUrl.searchParams.append('key', key);
              } else if (keysStatus[locale][key] === 'fetched')
                missingKeys.delete[key];
            });

            if (missingKeysUrl.searchParams.has('key')) {
              // if there is no translation after fetching, we still have fallback key as translation
              resourceBundle = {
                ...resourceBundle,
                ...missingKeysUrl.searchParams
                  .getAll('key')
                  .reduce((acc, cur) => ({ ...acc, [cur]: cur }), {}),
              };

              try {
                const res = await fetch(missingKeysUrl);
                const langPack = await res.json();
                const langPackKeys = Object.keys(langPack);

                if (langPackKeys.length) {
                  resourceBundle = {
                    ...resourceBundle,
                    ...langPack,
                  };
                }
              } catch (error) {
                resourceLog(
                  'i18n fetch /translations error when fetch missing keys',
                  error
                );
              } finally {
                // delete the fetched missing keys to avoid infinite loop
                missingKeysUrl.searchParams.getAll('key').forEach(key => {
                  missingKeys.delete(key);
                  keysStatus[locale][key] = 'fetched';
                });
              }
            }
          }

          if (Object.keys(resourceBundle).length) {
            i18n.addResourceBundle(
              locale,
              TranslationNamespace.DEFAULT,
              resourceBundle
            );
          }

          if (missingKeys.size || missingGroups.size) {
            // fetch again until the missingKeys and missingGroups both are empty
            fetchMissingTranslations();
          }
        }
      },
      Math.max(0, nextFetchTimestamp - Date.now())
    );
  };

  const languageDetectorOption = {
    detection: {
      // if the order is changed, please also change the i18n detection in nextjs/app/(webapp)/layout.jsx
      order: [
        // querystring is hard to control in PWA
        getIsInPwa() ? '' : 'querystring',
        'cookie',
        'navigator', // Google crawler and SSR don't have navigator
        // 'htmlTag', // Client side default (sync with SSR)
        // 'header', // SSR Accept-Language header (CDN)
        // 'localStorage',
        // 'path',
        // 'subdomain',
      ].filter(a => a),
      lookupQuerystring: 'lang',
      caches: ['cookie'],
      // default is Session Cookie, see: https://stackoverflow.com/a/36421888
      cookieMinutes: 30 * 24 * 60, // 30 days

      // fallback to a similar whitelist language
      // Example 1: Browser language is 'es'
      // if 'es' is not found in whitelist, first fallback to any whitelist language that starts with 'es-', then fallback to fallbackLng ('es' -> 'es-*' -> fallbackLng)
      // Example 2: Browser language is 'es-MX'
      // if 'es-MX' is not found in whitelist, first fallback to 'es', then fallback to 'es-*', then fallback to fallbackLng ('es-MX' -> 'es' -> 'es-*' -> fallbackLng)
      checkForSimilarInWhitelist: true,
    },
  };

  const clientHttpBackendOption = {
    ...i18CommonHttpBackendOption,
    addPath: locale => localeConvertor({ locale }),
    request: async (options, locale, payload, callback) => {
      // we don't need to load language in the beginning
      // overwrite the request to avoid the default request action
      // to separate the missing key and language changed
      // use missingKeyHandler to handle to the missing key
      // use language changed event to handle to language changed
      callback(null, { data: {}, status: 200 });
    },
  };

  const i18nOption = {
    ...i18nCommonOption,
    ...languageDetectorOption,
    interpolation: {
      ...i18nCommonOption.interpolation,
      escape: str => sanitize(str),
    },
    react: {
      useSuspense: false, // XXX: fix the bug when change language on the setting page
      bindI18nStore: 'added', // trigger re-render when i18n.addResourceBundle
    },
    saveMissing: true, // trigger missingKeyHandler
    saveMissingPlurals: false, // https://www.i18next.com/translation-function/plurals, we might handle un-exist key because we use count interpolation key
    parseMissingKeyHandler: key => {
      // if the key has the interpolation component format, return key to Trans component, and Trans component will handle it
      if (INTERPOLATION_REGEX.test(key)) return key;
      return '';
    },
    missingKeyHandler: (locales, namespace, key) => {
      if (isTranslationResourceLocked) return;

      if (
        key &&
        namespace &&
        namespace !== TranslationNamespace.DEFAULT &&
        !missingGroups.has(namespace)
      ) {
        missingGroups.add(namespace);
        usedGroups.add(namespace);
      }

      if (key && !INTERPOLATION_REGEX.test(key) && !missingKeys.has(key))
        missingKeys.add(key);

      fetchMissingTranslations();
    },
    partialBundledLanguages: true,
    initImmediate: false,
    backend: {
      backends: [
        resourcesToBackend(window.initialI18nStore || {}),
        HttpBackend,
      ],
      backendOptions: [null, clientHttpBackendOption],
    },
  };

  const i18n = I18n.createInstance(i18nOption)
    .use(ChainedBackend)
    .use(LanguageDetector);

  let prevLang = i18n.language;
  i18n.on('languageChanged', async locale => {
    if (!prevLang) {
      prevLang = locale;
      return;
    }

    if (i18n.isInitialized && prevLang !== locale) {
      // lock to avoid that missingKeyHandler being invoked, and then causing some unexpected translation
      isTranslationResourceLocked = true;

      prevLang = locale;
      let usedKeys = new Set();

      Object.keys(i18n.store?.data || {}).forEach(locale => {
        Object.keys(
          i18n.store?.data?.[locale]?.[TranslationNamespace.DEFAULT] || {}
        ).forEach(key => usedKeys.add(key));
      });

      let resourceBundle = {};

      if (usedGroups.size) {
        const usedGroupFetches = [];
        usedGroups.forEach(group => {
          const groupUrl = getTranslationsUrl(locale);
          groupUrl.searchParams.append('group', group);
          usedGroupFetches.push(fetch(groupUrl));
        });

        try {
          let allRes = await Promise.all(usedGroupFetches);
          const allLangPacks = await Promise.all(allRes.map(res => res.json()));
          const langPack = allLangPacks.reduce(
            (acc, cur) => ({ ...acc, ...cur }),
            {}
          );
          const langPackKeys = Object.keys(langPack);

          if (langPackKeys.length) {
            resourceBundle = {
              ...resourceBundle,
              ...langPack,
            };
            // delete fetched used keys from group
            langPackKeys.forEach(key => usedKeys.delete(key));
          }
        } catch (error) {
          resourceLog(
            'i18n fetch /translations error for groups when language changed',
            error
          );
        }
      }

      if (usedKeys.size) {
        usedKeys = [...usedKeys];

        const usedKeysFetches = [];
        // chunk the used key to avoid url length limitation
        chunk(usedKeys, I18N_LANGUAGE_CHANGED_CHUNK_SIZE).forEach(keys => {
          const usedKeysUrl = getTranslationsUrl(locale);
          keys.forEach(
            key => key && usedKeysUrl.searchParams.append('key', key)
          );
          usedKeysFetches.push(fetch(usedKeysUrl));
        });

        // if there is no translation after fetching, we still have fallback key as translation
        resourceBundle = {
          ...resourceBundle,
          ...usedKeys.reduce((acc, cur) => ({ ...acc, [cur]: cur }), {}),
        };

        try {
          const allRes = await Promise.all(usedKeysFetches);
          const allLangPacks = await Promise.all(allRes.map(res => res.json()));
          const langPack = allLangPacks.reduce(
            (acc, cur) => ({ ...acc, ...cur }),
            {}
          );
          const langPackKeys = Object.keys(langPack);

          if (langPackKeys.length) {
            resourceBundle = {
              ...resourceBundle,
              ...langPack,
            };
          }
        } catch (error) {
          resourceLog(
            'i18n fetch /translations error for keys when language changed',
            error
          );
        }
      }

      if (Object.keys(resourceBundle).length) {
        i18n.addResourceBundle(
          locale,
          TranslationNamespace.DEFAULT,
          resourceBundle
        );
      }

      // unlock so that missingKeyHandler can do the fetching things
      isTranslationResourceLocked = false;
    }
  });

  return i18n;
};
