// uploadConstants.js
'use strict';

export const UploadJobType = {
  POST: 'post',
  STORY: 'story',
  LIVESTREAM: 'livestream',
  FLIX: 'flix',
  KYC: 'kyc',
  ME: 'me',
  CLIP: 'clip',
  CHAT: 'chat',
};

export const Priority = {
  [UploadJobType.FLIX]: 1,
  [UploadJobType.POST]: 1,
  [UploadJobType.STORY]: 1,
  [UploadJobType.KYC]: 1,
  [UploadJobType.ME]: 1,
  [UploadJobType.CLIP]: 1,
  [UploadJobType.CHAT]: 2,
};

export const Status = {
  PENDING: 'pending',
  DELETING: 'deleting',
  FAILED: 'failed',

  UPLOAD_STARTED: 'upload.started',
  UPLOAD_FAILED: 'upload.failed',
  UPLOAD_COMPLETED: 'upload.completed',
  PROCESSING_STARTED: 'processing.started',
  PROCESSING_FAILED: 'processing.failed',
  PROCESSING_COMPLETED: 'processing.completed',
  REVIEW_STARTED: 'review.started',
  REVIEW_FAILED: 'review.failed',
  REVIEW_COMPLETED: 'review.completed',
  DELIVERY_STARTED: 'delivery.started',
  DELIVERY_FAILED: 'delivery.failed',
  DELIVERY_COMPLETED: 'delivery.completed',
};

export const PostDraftField = {
  TYPING_HASHTAG: 'typingHashtag',
  IS_FOCUS_ON_INPUT: 'isFocusOnInput',
  ENTRY: 'entry',
  UPLOAD_JOB_ID: 'uploadJobId',
  TITLE: 'title',
  CAPTION: 'caption',
  CATEGORIES: 'categories',
  UNLOCK_PRICE: 'unlockPrice',
  IS_UNLOCK_PRICE_EDITABLE: 'isUnlockPriceEditable',
  IS_AUP_CHECKED: 'isAupChecked',
  IS_AUP_CHECKBOX_EDITABLE: 'isAupCheckboxEditable',
  MESSAGE_ID: 'messageId',
  IS_SCHEDULE_ENABLED: 'isScheduleEnabled',
  SCHEDULED_AT_UNIX: 'scheduledAtUnix',
  IS_SCHEDULED_AT_UNIX_EDITABLE: 'isScheduledAtUnixEditable',
  IS_TTL_EDITABLE: 'isTtlEditable',
  IS_TTL_CHECKED: 'isTtlChecked',
  IS_LOADING: 'isLoading',
  IS_EDIT_MODE: 'isEditMode',
};

export const HookStatus = {
  RUNNING: 'running',
  COMPLETED: 'completed',
  FAILED: 'failed',
};
