// getNetworkingData.js
'use strict';
import { Collection } from 'immutable';
import createCachedSelector from '../resource/createCachedSelector.js';

/**
 * Select networking data by select path
 *
 * Notice: On SSR rendered html, number in select path (ex: page)
 * will be transformed into string by JSON.stringify. So need to
 * normalize to  by JSONstring for transfering redux store.
 *
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {array} selectPath - select path.
 * @param {string} dataKey - data key.
 * @return {any} The selected networking data.
 */
const getNetworkingData = createCachedSelector(
  (state, selectPath) =>
    state.getIn(['networkings', ...selectPath.map(p => `${p}`)]),
  (state, selectPath, dataKey) => dataKey,
  (networking, dataKey) => {
    let result = undefined;
    if (networking) {
      result = !dataKey ? networking : networking.get(dataKey);
      if (dataKey === 'fetchedUnixTimestamp') {
        result = Math.floor((networking.get('fetchedTimestamp') || 0) / 1000);
      }
    }
    return result instanceof Collection ? result.toJS() : result;
  }
)((state, selectPath, dataKey) => `${selectPath.join(':')}:${dataKey}`);

export default getNetworkingData;
