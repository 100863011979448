// updateConfigurations.js
'use strict';
import fetchRemoteConfig from '../action/fetchRemoteConfig.js';
import getOperationData from '../selector/getOperationData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import getConfigurations from '../selector/getConfigurations.js';
import getAbTestingData from '../selector/getAbTestingData.js';
import {
  MERGE_OPERATION_DATA,
  MERGE_REMOTE_CONFIG_DATA,
} from '../ActionTypes.js';
import { setRemoteMeta } from '../resource/mixpanel.js';
import { setAppBundleIds } from '../resource/getUserAgent.js';
import {
  API,
  WATCH,
  PUBLIC,
  RSS_FEED,
} from '../resource/resourceUrlTypeConstants.js';
import { defaultPublicUrlPathMap } from '../resource/publicUrlPathConstants.js';
import {
  APP_BUNDLE_IDS,
  API_URL_PREFIX,
  WATCH_URL_PREFIX,
  PUBLIC_URL_PREFIX,
  RSS_FEED_URL_PREFIX,
  SSR_API_URL_PREFIX,
} from '../RemoteConfigKeys.js';
import { updateEnv } from '../resource/env.js';

const ONE_WEEK_SECS = 7 * 24 * 60 * 60;
const isServer = typeof window === 'undefined';

export const resourceUrlConfigMaps = {
  [API_URL_PREFIX]: API,
  [WATCH_URL_PREFIX]: WATCH,
  [PUBLIC_URL_PREFIX]: PUBLIC,
  [RSS_FEED_URL_PREFIX]: RSS_FEED,
};

const getUrlConfigs = ({ remoteConfig }) => {
  const resourceUrl = {};
  Object.keys(resourceUrlConfigMaps).forEach(key => {
    resourceUrl[resourceUrlConfigMaps[key]] = remoteConfig[key];
  });
  if (isServer) {
    resourceUrl[API] = remoteConfig[SSR_API_URL_PREFIX] || resourceUrl[API];
  }

  const publicUrlPath = { ...defaultPublicUrlPathMap };
  Object.keys(defaultPublicUrlPathMap).forEach(key => {
    publicUrlPath[key] = remoteConfig[key] || publicUrlPath[key];
  });
  return { resourceUrl, publicUrlPath };
};

/**
 * Update configurations
 * @kind action
 * @param {string} {config} - target config key
 * @return {Promise} Action promise.
 */
const updateConfigurations =
  ({ configKey }) =>
  async (dispatch, getState) => {
    const selectPath = ['config'];

    let configurationGroups =
      getOperationData(getState(), selectPath, 'configurationGroups') || {};
    const defaultConfigKey = getOperationData(
      getState(),
      selectPath,
      'defaultConfigKey'
    );
    const configurations = getConfigurations(getState()) || [];
    const newConfigKey = configKey || defaultConfigKey;
    const configGroupsPriorityArray = Object.keys(configurationGroups)
      .map(priority => configurationGroups[priority][newConfigKey])
      .filter(priorityData => priorityData);

    const flatAllConfigIds = configGroupsPriorityArray.reduce((prev, curr) => {
      return prev.concat(curr);
    }, []);
    const uniqueAllConfigIds =
      flatAllConfigIds.length > 0
        ? [...new Set(flatAllConfigIds)]
        : configurations;

    const fetchedConfigIds = uniqueAllConfigIds.filter(configId => {
      const fetchedData = getOperationData(
        getState(),
        [...selectPath, 'configurationData'],
        configId
      );
      // Check networking isFetched once to avoid `fetchedData` out date.
      // e.g. PWA restored from indexedDB
      const isFetched = getNetworkingData(
        getState(),
        [...selectPath, 'configurations', configId],
        'isFetched'
      );

      if (fetchedData && isFetched) {
        return configId;
      }
      return false;
    });

    const needFetchConfigIds = uniqueAllConfigIds.filter(
      configId => !fetchedConfigIds.includes(configId)
    );

    await Promise.all(
      needFetchConfigIds.map(configId =>
        dispatch(fetchRemoteConfig({ configId }))
      )
    );

    // Avoid use outdated `configurationGroups` to generate remote config.
    configurationGroups =
      getOperationData(getState(), selectPath, 'configurationGroups') || {};

    const configGroupsDataAry = Object.keys(configurationGroups).map(
      priority => {
        const configKeys = configurationGroups[priority][newConfigKey] || [];
        const remoteConfigData = configKeys.reduce(
          (prev, current) =>
            Object.assign(
              prev,
              getOperationData(
                getState(),
                ['config', 'configurationData'],
                current
              )
            ),
          {}
        );
        const abTestData = getAbTestingData(
          getState(),
          undefined,
          'variableObject',
          priority
        );
        return Object.assign({}, remoteConfigData, abTestData);
      }
    );

    const remoteConfigResult = configGroupsDataAry.reduce(
      (prev, current) => Object.assign(prev, current),
      {}
    );

    updateEnv(remoteConfigResult);

    if (remoteConfigResult[APP_BUNDLE_IDS]) {
      setAppBundleIds({
        ids: remoteConfigResult[APP_BUNDLE_IDS]?.split(' '),
      });
    }

    const { resourceUrl, publicUrlPath } = getUrlConfigs({
      remoteConfig: remoteConfigResult,
    });
    if (!isServer) {
      document.cookie = `region=${newConfigKey};SameSite=Lax;max-age=${ONE_WEEK_SECS}`;
      window.__RESOURCE_URL__ = resourceUrl;
      window.__PUBLIC_URL_PATH__ = publicUrlPath;
    } else {
      globalThis.__RESOURCE_URL__ = resourceUrl;
      globalThis.__PUBLIC_URL_PATH__ = publicUrlPath;
    }

    setRemoteMeta({
      data: {
        'configuration.id': newConfigKey,
      },
    });
    dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath: ['config'],
        data: {
          currentConfigIds: uniqueAllConfigIds,
        },
      },
    });
    dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath: ['config'],
        data: {
          currentConfigKey: newConfigKey,
        },
      },
    });
    dispatch({
      type: MERGE_OPERATION_DATA,
      payload: {
        selectPath: ['showcase'],
        data: {
          activeIndex: 0,
        },
      },
    });
    return dispatch({
      type: MERGE_REMOTE_CONFIG_DATA,
      payload: remoteConfigResult,
    });
  };

export default updateConfigurations;
