// getMessageData.js
'use strict';
import { Collection } from 'immutable';
import { uniq } from 'lodash';
import createCachedSelector from '../resource/createCachedSelector.js';

import {
  MASQUED_PHRASES,
  MASQUED_PHRASES_FOR_CHAT,
} from '../RemoteConfigKeys.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getOperationData from '../selector/getOperationData.js';
import formatNumber from '../resource/formatNumber.js';
import { wordsFilter } from '../resource/wordsFilter.js';
import { localeConvertor } from '../resource/i18n.js';
import { MessageCtaType } from '../resource/messageConstants.js';
import { getI18nInstance } from '../resource/i18n.js';

/**
 * calculate percentage
 * algorithem: value / base * 100
 *
 * @param {object} payload - pyaload
 * @param {number} payload.value - value
 * @param {number} payload.base - base
 *
 * @return {number} return percentage
 */
const getPercentage = ({ value, base }) => {
  if (base === 0) {
    return 0;
  }
  return Math.floor((value / base) * 100);
};

const getForbiddenWordsRefs = (state, messageId) => {
  const messageType = state.getIn(['messages', messageId, 'type']);
  const remoteConfigKey =
    'chat' === messageType ? MASQUED_PHRASES_FOR_CHAT : MASQUED_PHRASES;

  return getRemoteConfigData(state, remoteConfigKey);
};

export const getMessageDataInChatroom = ({ message, chatroomId, key }) => {
  return message?.getIn(['chatroom', chatroomId, key]) || message?.get(key);
};

/**
 * Select message data by messageId, dataKey
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} messageId - select key.
 * @param {string} dataKey - select key.
 * @return {any} The selected message data.
 */
const getMessageData = createCachedSelector(
  (state, messageId) => messageId,
  (state, messageId) => state.getIn(['messages', messageId]),
  (state, messageId, dataKey) => dataKey,
  (state, messageId) => getForbiddenWordsRefs(state, messageId),
  state =>
    getOperationData(state, ['config'], 'language') ||
    getI18nInstance()?.i18n.language,
  (state, messageId, dataKey, chatroomId) => chatroomId,
  (
    messageId,
    message,
    dataKey,
    filterRefString,
    currentLanguage,
    chatroomId
  ) => {
    let result = undefined;

    // to normalize zh-tw to zh-TW
    const currentLocale = localeConvertor({ locale: currentLanguage });

    if (message) {
      result = message.get(dataKey);
      if ('title' === dataKey) {
        result = message.getIn(['i18nTitle', currentLocale]) || result || '';
        result = wordsFilter({ value: result, filterRefString });
      } else if ('originalTitle' === dataKey) {
        const title = message.get('title');
        result = wordsFilter({ value: title || '', filterRefString });
      } else if ('captionText' === dataKey) {
        result = message.getIn(['caption', 'text']) || message.get('caption');
        result = message.getIn(['i18nCaption', currentLocale]) || result;
        result = wordsFilter({ value: result || '', filterRefString });
      } else if ('originalCaptionText' === dataKey) {
        const originalCaptionText =
          message.getIn(['caption', 'text']) || message.get('caption');
        result = wordsFilter({
          value: originalCaptionText || '',
          filterRefString,
        });
      } else if ('description' === dataKey) {
        const i18nDescription = message.getIn([
          'i18nDescription',
          currentLocale,
        ]);
        result = i18nDescription || '';
        result = wordsFilter({ value: result, filterRefString });
      } else if ('originalText' === dataKey) {
        result = wordsFilter({
          value: message.get('text') || message.get('caption') || '',
          filterRefString,
        });
      } else if ('text' === dataKey) {
        result = wordsFilter({
          value:
            message.getIn(['i18nText', currentLocale]) ||
            message.get('text') ||
            message.get('caption') ||
            '',
          filterRefString,
        });
      } else if ('hasTranslation' === dataKey) {
        result = false;
        const i18nTitle = message.getIn(['i18nTitle', currentLocale]) || '';
        const i18nCaption = message.getIn(['i18nCaption', currentLocale]) || '';
        const i18nDescription =
          message.getIn(['i18nDescription', currentLocale]) || '';
        const i18nText = message.getIn(['i18nText', currentLocale]) || '';
        if (i18nTitle || i18nCaption || i18nDescription || i18nText) {
          result = true;
        }
      } else if ('captionAxis' === dataKey) {
        const captionAxis = {
          x: message.getIn(['caption', 'x']),
          y: message.getIn(['caption', 'y']),
        };
        if (undefined !== captionAxis.x || undefined !== captionAxis.y) {
          result = captionAxis;
        }
      } else if ('mediaType' === dataKey) {
        const media = message.get('media');
        result =
          message.getIn(['media', 'type']) ||
          ('string' === typeof media ? media : undefined);
      } else if ('mediaDuration' === dataKey) {
        result =
          message.getIn(['media', 'duration']) || message.get('duration');
      } else if ('viewScope' === dataKey) {
        result = message.get('viewScope') || 'preview';
      } else if ('canViewMedia' === dataKey) {
        const viewScope = message.get('viewScope');
        if (viewScope == null) {
          result = null;
        } else {
          result = ['sd', 'hd'].includes(message.get('viewScope'));
        }
      } else if ('likes' === dataKey) {
        const likes = message.get('likes') || 0;
        const ratedAs = message.get('ratedAs');
        result = ratedAs === 100 ? likes + 1 : likes;
      } else if ('dislikes' === dataKey) {
        const dislikes = message.get('dislikes') || 0;
        const ratedAs = message.get('ratedAs');
        result = ratedAs === 0 ? dislikes + 1 : dislikes;
      } else if ('blob' === dataKey) {
        result = message.getIn(['media', 'fileBlob']);
      } else if ('likePercentage' === dataKey) {
        if (!message.get('ratings')) return null;
        const rawLikes = message.get('likes');
        const rawDislikes = message.get('dislikes');
        const ratedAs = message.get('ratedAs');
        const likes = (rawLikes || 0) + (ratedAs === 100 ? 1 : 0);
        const dislikes = (rawDislikes || 0) + (ratedAs === 0 ? 1 : 0);
        const total = likes + dislikes;
        if (total === 0) return null;
        result = getPercentage({ value: likes, base: total });
      } else if ('likePercentageWithSignOrDefault' === dataKey) {
        if (!message.get('ratings')) return '-';
        const ratedAs = message.get('ratedAs');
        const likes = (message.get('likes') || 0) + (ratedAs === 100 ? 1 : 0);
        const dislikes =
          (message.get('dislikes') || 0) + (ratedAs === 0 ? 1 : 0);
        const total = likes + dislikes;

        const percentage = getPercentage({
          value: likes,
          base: total,
        });
        result = total ? `${percentage}%` : '-';
      } else if ('likesWithDefault' === dataKey) {
        const likes = message.get('likes') || 0;
        result = likes ? formatNumber(likes) : '-';
      } else if ('unlocksWithDefault' === dataKey) {
        const unlocks = message.get('unlocks') || 0;
        result = unlocks ? formatNumber(unlocks) : '-';
      } else if ('isFlix' === dataKey) {
        const badges = message.get('badges') || [];
        const isFlix = badges.includes('flix');
        result = isFlix;
      } else if ('isPro' === dataKey) {
        const badges = message.get('badges') || [];
        result = badges.includes('pro');
      } else if ('hasFreePeek' === dataKey) {
        const badges = message.get('badges') || [];
        result = badges.includes('free_peek');
      } else if ('viewCountWithDefault' === dataKey) {
        const views = message.get('views') || 0;
        result = views ? formatNumber(views) : '-';
      } else if ('isAutoMessage' === dataKey) {
        const badges = message.get('badges') || [];
        result = badges.includes('type:auto');
      } else if ('hasAmateur' === dataKey) {
        const badges = message.get('badges') || [];
        result = badges.includes('amateur');
      } else if ('castIds' === dataKey) {
        const cast = message.get('cast') || [];
        result = cast.map(item => item.get('id'));
      } else if ('hasPackBadge' === dataKey) {
        // Indicate message has message pack badge
        const badges = message.get('badges') || [];
        result = badges.includes('pack');
      } else if ('ctaUrlData' === dataKey) {
        // Indicate cta url's data
        const ctaUrl = message.get('ctaUrl') || '';
        // /product/:id
        const productDetailExecResult = /^\/product\/(?:([^/]+?))\/?$/i.exec(
          ctaUrl
        );
        // /post/:id
        const postDetailExecResult = /^\/post\/(?:([^/]+?))\/?$/i.exec(ctaUrl);
        // /user/:id/livestream
        const livestreamExecResult =
          /^\/user\/(?:([^/]+?))\/livestream\/?$/.exec(ctaUrl);
        if (productDetailExecResult) {
          result = {
            type: MessageCtaType.PRODUCT,
            productId: productDetailExecResult[1],
            ctaUrl,
          };
        } else if (postDetailExecResult) {
          result = {
            type: MessageCtaType.POST,
            messageId: postDetailExecResult[1],
            ctaUrl,
          };
        } else if (livestreamExecResult) {
          result = {
            type: MessageCtaType.LIVESTREAM,
            userId: livestreamExecResult[1],
            ctaUrl,
          };
        } else {
          result = undefined;
        }
      } else if ('isPublished' === dataKey) {
        result = message.get('postedAtUnix') != null;
      } else if ('postedAtUnix' === dataKey) {
        result = getMessageDataInChatroom({
          message,
          chatroomId,
          key: dataKey,
        });
      } else if ('categories' === dataKey) {
        if (result instanceof Collection) result = result.toJS();
        result = uniq(result || []);
      }
    }
    return result instanceof Collection ? result.toJS() : result;
  }
)((state, messageId, dataKey, chatroomId) => {
  return `${messageId}:${dataKey}:${chatroomId}`;
});

export default getMessageData;
