// joinConstants.js
'use strict';

import PassportAExampleSource from '../../img/ic_kyc_passport_a.svg';
import PassportBExampleSource from '../../img/ic_kyc_passport_b.svg';
import PassportCExampleSource from '../../img/ic_kyc_passport_c.svg';
import IdAExampleSource from '../../img/ic_kyc_idcard_a.svg';
import IdBExampleSource from '../../img/ic_kyc_idcard_b.svg';
import IdCExampleSource from '../../img/ic_kyc_idcard_c.svg';
import DriverLicenseAExampleSource from '../../img/ic_kyc_driver_a.svg';
import DriverLicenseBExampleSource from '../../img/ic_kyc_driver_b.svg';
import DriverLicenseCExampleSource from '../../img/ic_kyc_driver_c.svg';
import HICExampleSource from '../../img/ic_kyc_health_a.svg';
import IdExampleSource from '../../img/ic_kyc_idcard_a.svg';
import SelfieAExampleSource from '../../img/ic_kyc_selfie_a.svg';
import SelfieBExampleSource from '../../img/ic_kyc_selfie_b.svg';
import SelfieCExampleSource from '../../img/ic_kyc_selfie_c.svg';
import DriverLicenseFrontBackAExampleSource from '../../img/ic_kyc_driver_front_back_a.svg';
import DriverLicenseFrontBackBExampleSource from '../../img/ic_kyc_driver_front_back_b.svg';
import IdFrontBackAExampleSource from '../../img/ic_kyc_idcard_front_back_a.svg';
import IdFrontBackBExampleSource from '../../img/ic_kyc_idcard_front_back_b.svg';

export const Progress = {
  PERSONAL_INFORMATION: 'personal-information',
  UPLOAD_IDENTIFICATION: 'upload-identification',
  OCR_PROCESSING: 'ocr-processing',
  OCR_RESULT: 'ocr-result',
  MANUAL_REVIEW: 'manual-review',
  CONTRACT: 'contract',
  BECOME_SWAGGER: 'become-swagger',
};

export const JoinProgressValue = [
  [Progress.PERSONAL_INFORMATION],
  [Progress.UPLOAD_IDENTIFICATION, Progress.OCR_PROCESSING],
  [Progress.OCR_RESULT],
  [Progress.MANUAL_REVIEW],
];

export const DocumentType = {
  PASSPORT: 'passport',
  ID: 'id',
  DRIVER_LICENSE: 'driverLicense',
  HEALTH_INSURANCE_CARD: 'healthInsuranceCard',
  SELFIE: 'selfie',
};

export const JoinField = {
  COUNTRY: 'country',
  GENDER: 'gender',
  REFERRAL_CODE: 'referralCode',
  PHOTO_UPLOADER_ENTRY: 'photoUploaderEntry',
  DOCUMENT_TYPE: 'documentType',
  FACE: 'face',
  DOCUMENT: 'document',
  DOCUMENT_BACK: 'documentBack',
  OCR_RESULT: 'ocrResult',
  OCR_RESULT_CONFIRMED: 'ocrResultConfirmed',
};

export const Gender = {
  MALE: 'M',
  FEMALE: 'F',
};

export const CREATOR_PROOFS = [JoinField.FACE, JoinField.DOCUMENT];
export const USER_PROOFS = [JoinField.DOCUMENT];
export const PROOFS = [
  JoinField.FACE,
  JoinField.DOCUMENT,
  JoinField.DOCUMENT_BACK,
];

/**
 * @typedef {object} DocumentObject
 * @property {string} titleI18nKey - document object title i18n key.
 * @property {string} descriptionI18nKey - document object description i18n key.
 * @property {array} examples - document object example icons.
 */
const defaultDocumentObject = {
  titleI18nKey: '',
  descriptionI18nKey: '',
  examples: [],
};

/**
 * Get join document object
 * @param {DocumentType} {documentType} - document type.
 * @param {boolean} {isJoinCreator} - is join creator or user age verification.
 * @param {boolean} {isDocumentFrontOnly} - is document front only.
 * @return {DocumentObject|undefined} return document object.
 */
export const getJoinDocumentObject = ({
  documentType,
  isJoinCreator,
  isDocumentFrontOnly,
} = {}) => {
  switch (documentType) {
    case DocumentType.SELFIE: {
      return {
        titleI18nKey: 'selfie_upload',
        descriptionI18nKey: 'selfie_description',
        examples: [
          {
            id: 'selfieA',
            src: SelfieAExampleSource,
            alt: 'selfie_description',
            ['aria-hidden']: true,
          },
          {
            id: 'selfieB',
            src: SelfieBExampleSource,
            alt: 'selfie_description',
            ['aria-hidden']: true,
          },
          {
            id: 'selfieC',
            src: SelfieCExampleSource,
            alt: 'selfie_description',
            ['aria-hidden']: true,
          },
        ],
      };
    }
    case DocumentType.PASSPORT: {
      if (isJoinCreator) {
        return {
          titleI18nKey: 'passport',
          descriptionI18nKey: 'passport_description',
          examples: [
            {
              id: 'passportA',
              src: PassportAExampleSource,
              alt: 'passport_description',
              ['aria-hidden']: true,
            },
            {
              id: 'passportB',
              src: PassportBExampleSource,
              alt: 'passport_description',
              ['aria-hidden']: true,
            },
            {
              id: 'passportC',
              src: PassportCExampleSource,
              alt: 'passport_description',
              ['aria-hidden']: true,
            },
          ],
        };
      }
      return defaultDocumentObject;
    }
    case DocumentType.ID: {
      if (isJoinCreator) {
        return {
          titleI18nKey: 'id_card',
          descriptionI18nKey: isDocumentFrontOnly
            ? 'id_card_description'
            : 'id_card_front_back_description',
          examples: isDocumentFrontOnly
            ? [
                {
                  id: 'idAFrontOnly',
                  src: IdAExampleSource,
                  alt: 'id_card_description',
                  ['aria-hidden']: true,
                },
                {
                  id: 'idBFrontOnly',
                  src: IdBExampleSource,
                  alt: 'id_card_description',
                  ['aria-hidden']: true,
                },
                {
                  id: 'idCFrontOnly',
                  src: IdCExampleSource,
                  alt: 'id_card_description',
                  ['aria-hidden']: true,
                },
              ]
            : [
                {
                  id: 'idA',
                  src: IdFrontBackAExampleSource,
                  alt: 'id_card_front_back_description',
                  ['aria-hidden']: true,
                },
                {
                  id: 'idB',
                  src: IdFrontBackBExampleSource,
                  alt: 'id_card_front_back_description',
                  ['aria-hidden']: true,
                },
              ],
        };
      }
      return {
        titleI18nKey: 'id_card',
        descriptionI18nKey: 'id_card_user_description',
        examples: [
          {
            id: 'idAUser',
            src: IdExampleSource,
            alt: 'id_card_user_description',
            ['aria-hidden']: true,
          },
        ],
      };
    }
    case DocumentType.DRIVER_LICENSE: {
      if (isJoinCreator) {
        return {
          titleI18nKey: 'driver_license',
          descriptionI18nKey: isDocumentFrontOnly
            ? 'driver_license_description'
            : 'driver_license_front_back_description',
          examples: isDocumentFrontOnly
            ? [
                {
                  id: 'driverLicenseAFrontOnly',
                  src: DriverLicenseAExampleSource,
                  alt: 'driver_license_description',
                  ['aria-hidden']: true,
                },
                {
                  id: 'driverLicenseBFrontOnly',
                  src: DriverLicenseBExampleSource,
                  alt: 'driver_license_description',
                  ['aria-hidden']: true,
                },
                {
                  id: 'driverLicenseCFrontOnly',
                  src: DriverLicenseCExampleSource,
                  alt: 'driver_license_description',
                  ['aria-hidden']: true,
                },
              ]
            : [
                {
                  id: 'driverLicenseA',
                  src: DriverLicenseFrontBackAExampleSource,
                  alt: 'driver_license_front_back_description',
                  ['aria-hidden']: true,
                },
                {
                  id: 'driverLicenseB',
                  src: DriverLicenseFrontBackBExampleSource,
                  alt: 'driver_license_front_back_description',
                  ['aria-hidden']: true,
                },
              ],
        };
      }
      return defaultDocumentObject;
    }
    case DocumentType.HEALTH_INSURANCE_CARD: {
      if (isJoinCreator) {
        return defaultDocumentObject;
      }
      return {
        titleI18nKey: 'health_insurance_card',
        descriptionI18nKey: 'health_insurance_card_description',
        examples: [
          {
            id: 'healthInsuranceA',
            src: HICExampleSource,
            alt: 'health_insurance_card_description',
            ['aria-hidden']: true,
          },
        ],
      };
    }
    default: {
      return defaultDocumentObject;
    }
  }
};

export const CREATOR_DOCUMENT_TYPES = [
  DocumentType.PASSPORT,
  DocumentType.ID,
  DocumentType.DRIVER_LICENSE,
];
export const USER_DOCUMENT_TYPES = [
  DocumentType.HEALTH_INSURANCE_CARD,
  DocumentType.ID,
];
