// fetchMeSettings.js
'use strict';
import fetch from '../resource/customFetch.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';
import getMeData from '../selector/getMeData.js';
import {
  MERGE_ME_DATA,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

const selectPath = ['me', 'settings'];

/**
 * Fetch me settings
 * @kind action
 * @return {Promise} Action promise.
 */
const fetchMeSettings = () => async (dispatch, getState) => {
  const token = getMeData(getState(), 'token');
  if (!token) return dispatch({ type: '' });

  const url = getResourceUrl({ endpoint: '/me/settings' });
  const fetchOptions = {
    headers: {
      ...getHeaders(),
      Authorization: `Bearer ${token}`,
    },
  };

  dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });
  try {
    let response = await fetch(url.href, fetchOptions);

    if (200 !== response.status) {
      response = await handleFetchError({
        response,
        dispatch,
        getState,
        fetchOptions,
        fetchUrl: url,
      });
    }

    const result = await response.json();
    dispatch({ type: SET_NETWORKING_SUCCESS, payload: { selectPath } });
    return dispatch({ type: MERGE_ME_DATA, payload: { settings: result } });
  } catch (error) {
    return dispatch({
      type: SET_NETWORKING_ERROR,
      payload: { selectPath, error },
    });
  }
};

export default fetchMeSettings;
