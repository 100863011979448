// drmConstants.js
'use strict';

export const drmRulesetTestStatus = {
  TESTING: 'testing',
  TESTED: 'tested',
};

export const drmRulesetTestResult = {
  SUPPORTED: 'supported',
  UNSUPPORTED: 'unsupported',
  TIMEOUT: 'timeout',
};

export const COM_WIDEVINE_ALPHA = 'com.widevine.alpha';
export const COM_APPLE_FPS = 'com.apple.fps';
export const COM_MICROSOFT_PLAYREADY = 'com.microsoft.playready';
export const COM_MICROSOFT_PLAYREADY_HARDWARE =
  'com.microsoft.playready.hardware';
export const COM_MICROSOFT_PLAYREADY_RECOMMENDATION_3000 =
  'com.microsoft.playready.recommendation.3000';
export const COM_MICROSOFT_PLAYREADY_RECOMMENDATION =
  'com.microsoft.playready.recommendation';
export const ORG_W3_CLEARKEY = 'org.w3.clearkey';

// Chromium edge can use both playread and widevine, set the playready priority is higher than widevine.
// 14: com.microsoft.playready.recommendation.3000
// 13: com.microsoft.playready.recommendation + robustness 3000
// 12: com.microsoft.playready.hardware
// 11: widevine:HW_ALL
// 10: widevine:HW_DECODE
// 09: widevine:HW_CRYPTO
// 08: com.microsoft.playready.recommendation + robustness 2000
// 07: com.microsoft.playready + robustness 2000
// 06: widevine:SW_DECODE
// 05: widevine:SW_CRYPTO
// 04: com.microsoft.playready.recommendation + robustness 150
// 03: com.microsoft.playready + robustness 150
// 02: org.w3.clearkey:HW_ALL
// 01: org.w3.clearkey:SW_CRYPTO
export const DrmRobustnessPriority = {
  [COM_APPLE_FPS]: {
    'com.apple.fps.1_0': 12,
  },
  [COM_MICROSOFT_PLAYREADY_RECOMMENDATION_3000]: {
    ['3000']: 14,
  },
  [COM_MICROSOFT_PLAYREADY_RECOMMENDATION]: {
    ['3000']: 13,
    ['2000']: 8,
    ['150']: 4,
  },
  [COM_MICROSOFT_PLAYREADY_HARDWARE]: {
    ['3000']: 12,
  },
  [COM_MICROSOFT_PLAYREADY]: {
    ['2000']: 7,
    ['150']: 3,
  },
  [COM_WIDEVINE_ALPHA]: {
    HW_SECURE_ALL: 11,
    HW_SECURE_DECODE: 10,
    HW_SECURE_CRYPTO: 9,
    SW_SECURE_DECODE: 6,
    SW_SECURE_CRYPTO: 5,
  },
  [ORG_W3_CLEARKEY]: {
    HW_SECURE_ALL: 2,
    SW_SECURE_CRYPTO: 1,
  },
};

export const DrmFeatureName = {
  FLIX: 'flix',
  STORY: 'story',
  CHAT: 'chat',
  LIVESTREAM: 'livestream',
  SHORT: 'short',
  POST: 'post',
};
