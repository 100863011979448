// getCurrentUnixTimestamp.js
'use strict';

/**
 * Get current timestamp in seconds
 * @param {number} {offsetSeconds = 0} - offset seconds.
 * @return {number} current client unix timestamp with offset.
 */
const getCurrentUnixTimestamp = ({ offsetSeconds = 0 } = {}) =>
  Math.floor(Date.now() / 1000) + offsetSeconds;

export default getCurrentUnixTimestamp;
